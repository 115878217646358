<template>
    <div class="teaching-research-course-schedule-temp">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main">
               <filter-wrapper
                    :queryList="queryList"
                    :statusList="statusList"
                    :schedulingClassesList="schedulingClassesList"
                    :teacherList="teacherList"
                    :gradeList="gradeList"
                    :formData="formData"
                    @handleChangeCourseType="handleChangeCourseType"
                    @handlerChangeStatus="handlerChangeStatus"
                    @changeCascader="changeCascader"
                    @handlerAddClick="handlerAddClick"
                    @batchDeleteClick="batchDeleteClick"
                    @emptyTableClick="emptyTableClick"
                    @handlerImportClick="handlerImportClick"
                    @handlerExportClick="handlerExportClick"
                    @handlerScheduleClassIdChange="handlerScheduleClassIdChange"
                    @handlerClassTeacherChange="handlerClassTeacherChange"
                    @handlerTeacherChange="handlerTeacherChange"
                    @handlerGradeChange="handlerGradeChange"
               >
               </filter-wrapper>
               <course-schedule-view
                    v-loading="getDataLoading"
                    :advanceTime="advanceTime"
                    :getDataLoading="getDataLoading"
                    :classAndTeacher="classAndTeacher"
                    :queryList="queryList"
                    :courseList="courseList"
                    :weekList="weekList"
                    :dayList="dayList"
                    :isTourClass="isTourClass"
                    :type="queryList.courseType"
                    :courseScheduleDetailSubjectsList="courseScheduleDetailSubjectsList"
                    :courseScheduleDetailTeachersList="courseScheduleDetailTeachersList"
                    :showDownloadPDFType="showDownloadPDFType"
                    @handlerClassPatrolClick="handlerClassPatrolClick"
                    @delAllCourses="delAllCourses"
                    @edit="edit"
                    @dataAddCourse="dataAddCourse"
                    @del="del"
                    @detailDay="detailDay"
                    @addWeekCourseSchedule="addWeekCourseSchedule"
                    @onceDelCourse="onceDelCourse"
                    @changeCheck="changeCheck"
                    @tourDetails="tourDetails"
                    @goToDetail="goToDetail"
                    @lectureSetting="lectureSetting"
               >
               </course-schedule-view>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
        <!-- 点击巡课 -->
        <dialog-wrapper :dialogObj="dialogClassPatrol" @handleClose="handleCloseClassPatrol">
            <div class="request-pool">
                <template>
                    <div
                        v-for="(item, index) in tourDataList"
                        :key="index"
                        class="pool"
                        @click="requestPool(item)"
                        >
                        {{ item.name }}
                    </div>
                </template>
                <template>
                    <el-checkbox-group v-model="checkClassPatrolList">
                        <el-checkbox
                            v-for="item in classPatrolList"
                            :label="item.value"
                            :key="item.value"
                        >
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </template>
            </div>
            <div class="options-btn" v-if="classPatrolList.length > 0">
                <el-button type="primary" @click="handlerGoClassPatrolClick" v-hasPermi="['teachingResearchCourseScheduleTemp:tour']">巡课</el-button>
            </div>
        </dialog-wrapper>
        <!-- 详情/编辑 -->
        <dialog-wrapper v-if="dialogDetailsEditor.dialogVisible" :dialogObj="dialogDetailsEditor" @handleClose="handleCloseClassDetailsEditor">
            <course-schedule-detail
                ref="courseScheduleDetailWrapper"
                :currentCourseIsTour="currentCourseIsTour"
                :formData="formData"
                :queryList="queryList"
                :comPointer="comPointer"
                :dayDetail="dayDetail"
                :repeatClassDisabled="repeatClassDisabled"
                :courseScheduleDetail="courseScheduleDetail"
                :courseScheduleDetailSubjectsList="courseScheduleDetailSubjectsList"
                :courseScheduleDetailTeachersList="courseScheduleDetailTeachersList"
                :courseScheduleDetailSupervisingTeachersList="courseScheduleDetailSupervisingTeachersList"
                :type="queryList.courseType"
                :courseScheduleDetailRules="courseScheduleDetailRules"
                :schedulingClassesListFilter="schedulingClassesListFilter"
                :showOption="showOption"
                @handlerRepeatClassChange="handlerRepeatClassChange"
                @datePickerChange="datePickerChange"
                @changeCascader="changeCascader"
                @detailEdit="detailEdit"
                @detailDelSingleCourse="detailDelSingleCourse"
                @detailDelAllCourses="detailDelAllCourses"
                @detailDetermine="detailDetermine"
                @detailCancellation="detailCancellation"
            ></course-schedule-detail>
        </dialog-wrapper>

        <!-- 列表新增重复 -->
        <dialog-wrapper :dialogObj="dialogDetailsDuplicate" @handleClose="handleCloseClassDetailsDuplicate">
            <template>
                <div class="tips" v-for="(item, index) of tips" :key="index">
                    {{ item.scheduleTime }}  {{' ' + item.className}} {{' ' + item.restTimeName}} {{' ' + item.subjectName}} {{'不可重复排课'}}
                </div>
            </template>
            <template>
                <el-radio-group v-model="courseScheduleDetail.repeatType">
                    <el-radio label="1">忽略重复信息，覆盖原有排课信息</el-radio>
                </el-radio-group>
            </template>
            <div class="btn-wrapper">
                <el-button type="primary" @click="handlerRepeatResults">确认</el-button>
                <el-button @click="handleCloseClassDetailsDuplicate">取消</el-button>
            </div>
        </dialog-wrapper>
        <dialog-wrapper :dialogObj="dialogExportPdf" @handleClose="handleCloseExportPdf">
            <export-pdf-preview :courseList="courseList" :weekList="weekList">
                <course-schedule-view
                    v-loading="getDataLoading"
                    :queryList="queryList"
                    :courseList="courseList"
                    :weekList="weekList"
                    :loding="loding"
                    :dayList="dayList"
                    :isTourClass="isTourClass"
                    :type="queryList.courseType"
                    :courseScheduleDetailSubjectsList="courseScheduleDetailSubjectsList"
                    :courseScheduleDetailTeachersList="courseScheduleDetailTeachersList"
                    :showDownloadPDFType="showDownloadPDFType"
                    @handlerClassPatrolClick="handlerClassPatrolClick"
                    @delAllCourses="delAllCourses"
                    @edit="edit"
                    @dataAddCourse="dataAddCourse"
                    @del="del"
                    @detailDay="detailDay"
                    @addWeekCourseSchedule="addWeekCourseSchedule"
                    @tourDetails="tourDetails"
                    @goToDetail="goToDetail"
                    @lectureSetting="lectureSetting"
               >
               </course-schedule-view>
            </export-pdf-preview>
        </dialog-wrapper>

        <dialog-wrapper :dialogObj="importDialogObj" @handleClose="handleImportClose">
                    <exam-import
                        ref="importRef"
                        :active="importActive"
                        :import-form="importForm"
                        :upload-excel-header="uploadExcelHeader"
                        :system-excel-header="systemExcelHeader"
                        :key-map="keyMap"
                        :import-info="importInfo"
                        :importLoading="importLoading"
                        :success-data="importSuccessData"
                        :show="false"
                        @uploadChange="importUploadChange"
                        @uploadRemove="importUploadRemove"
                        @next="handleImportNext"
                        @before="handleImportBefore"
                        @close="handleImportClose"
                        @importSelChange="importSelChange"
                        @download="downloadImportTemplate"
                        @save="handleImportSave"
                    >
                        <div></div>
                    </exam-import>
        </dialog-wrapper>
        <!-- 清空课程表弹框 -->
        <dialog-wrapper :dialogObj="dialogDetailsEmpty" @handleClose="handleCloseClassDetailsEmpty">
            <el-form
              :model="emptydata"
              :rules="emptyDataRules"
              ref="emptydata"
              label-width="120px"
            >
                <!--<el-form-item label="清空方式"  label-position="right" prop="emptyvalue">
                    <el-radio-group style="margin-top: 8px;"  v-model="emptydata.emptyvalue" @change="emptyChange">
                        <el-radio  label="0">只清空未巡课课程</el-radio>
                        <el-radio  label="1">全部清空</el-radio>
                    </el-radio-group>
                </el-form-item>-->
              <el-form-item label="选择班级" prop="emptyclass"  label-position="right">
                <cascader style="width: 400px;" ref="J_cascader" v-model="emptydata.emptyclass" :defaultclassid="emptydata.emptyclass" :data="cascaderMutiData"  @changeCascader="changeCascader">
                </cascader>
              </el-form-item>
              <el-form-item label="选择日期" prop="emptydate">
                <DatePicker ref="J_datePicker"
                    v-model="emptydata.emptydate"
                    :clearable="PickerDate.clearable || true"
                    :style="{width: '400px'}"
                    :data="PickerDate"
                    @datePickerChange="emptydatePickerChange"
                    />
              </el-form-item>
              <div :style="{ 'text-align': 'right' }">
                <xk-button type="info" @click="handleCloseClassDetailsEmpty"
                  >取消</xk-button
                >
                <xk-button type="primary" @click="sureClear"
                  >确定</xk-button
                >
              </div>
            </el-form>
        </dialog-wrapper>
    </div>
</template>
<script>
import {
    // 业务组件
    FilterData,
    TableData,
    Pagination,
    // 功能组件
    Loading,
    Error,
    DialogWrapper,
    // 工具函数
    debounce
} from 'common-local';
import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";
import {formatTreeData} from "@/libs/utils";
import FilterWrapper from './FilterData.vue';
import CourseScheduleView from './CourseSchedule.vue';
import CourseScheduleDetail from './CourseScheduleDetail.vue';
import Cascader from './Cascaderview/Cascader'

import ExportPdfPreview from './ExportPdfPreview/index.vue';
import DatePicker from "../Sub/FormData/DatePicker.vue";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";

import { mapState } from 'vuex';

import { CourseScheduleTemp } from '@/operations/teachingResearchCourseScheduleTemp.js'
import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js';

// 导入弹窗件
import examImport from "./CourseScheduleView/ExamImport/index.vue";
import ExamImportMore from "../Summary/ExamImportMore/index.vue";
export default {
    name: "TeachingResearchCourseScheduleTempScrollWrapper",
    components: {
        Loading,
        Error,
        examImport,
        ExamImportMore,
        DialogWrapper,
        FilterWrapper,
        CourseScheduleView,
        CourseScheduleDetail,
        ExportPdfPreview,
        DatePicker,
        ExpandFilter,
        Cascader
    },
    data () {
        return {
            loding:true,//切换课表类型
            errorShow: false,
            loadingShow: false,
            getDataLoading: false,
            queryList: {
                schoolId: '',
                productId: '',
                dayWeek: 'week',
                isTourClass: '',
                startTime: '',
                endTime: '',
                selectDay: '',
                classTeacherId: '',
                courseType: '1',
                scheduleClassId: '',
                scheduleGradeId: '' // 年级
            },
            statusList: [
                {
                    id: 'day',
                    label: '日'
                },
                {
                    id: 'week',
                    label: '周'
                }
            ],
            emptydata:{
                scheduleClassId:'',
                emptyvalue:'1',
                emptyclass:"",
                emptydate:""
            },
            emptyDataRules: {
            //   emptyvalue : [{ required: true, message: "请选择清空方式", trigger: "blur" }],
              emptyclass: [{ required: true, message: "请选择班级", trigger: "blur" }],
              emptydate: [{ required: true, message: "请选择日期", trigger: "blur" }],
            },
            PickerDate:{
                type: 'datePick',
                label: '',
                startPlaceholder: '开始日期',
                endPlaceholder: '结束日期',
                key: 'endTime',
                value: '',
                        // picker: true,
                        // format: 'yyyy-MM-dd HH:mm:ss'
            },
            /**
             * 筛选数据
             * schedulingClassesList 班级
             * schedulingClassesListFilter 筛选班级
             * teacherList 教师
             * supervisingTeacherList 督导教师
             * gradeList 年级列表
             * */
            schedulingClassesList: [],
            schedulingClassesListFilter: [],
            teacherList: [],
            supervisingTeacherList: [],
            gradeList: [],
            filterQueryList: {
                schoolId: '',
                productId: '',
                dayWeek: 'week',
                isTourClass: '',
                startTime: '',
                endTime: '',
                selectDay: '',
                classTeacherId: '',
                courseType: '1',
                scheduleClassId: '',
                scheduleGradeId: '', // 年级
            },
            /**
             * 根据年级查询课程时间 conditionList
             * 上课时间表,左侧渲染时间 courseList
             * */
            conditionList: {
                schoolId: '',
                productId: '',
                classId: '',
                isArrange: '',
                teacherId: '',
                gradeId: ''
            },
            courseList: [],
            weekList: [],
            dayList: [],

            /**
             * 弹窗相关
             * dialogDetailsEmpty 清空课程表
             * dialogClassPatrol 巡课弹窗数据集合
             * dialogDetailsEditor 详情/编辑数据集合
             * dialogDetailsDuplicate 新增/重复
             * checkClassPatrolList 选择巡课选择训课教师
             * classPatrolList 巡课老师列表
             * tourDataList 已经巡课列表
             *
             * comPointer 详情/编辑组件数据集合
             * dayDetail/ 是否为单日详情
             * repeatClassDisabled 是否为编辑
             * courseScheduleDetail 新增/编辑数据合集
             * courseScheduleDetailSubjectsList 科目列表
             * courseScheduleDetailTeachersList 教师列表
             * courseScheduleDetailSupervisingTeachersList 督导教师
             * */
            dialogDetailsEmpty: {
                title: '清空课程表',
                dialogVisible: false,
                width: '600px',
            },
            dialogClassPatrol: {
                title: '课程督导',
                dialogVisible: false,
                width: '750px',
            },
            dialogDetailsEditor: {
                title: '课程表',
                dialogVisible: false,
                width: '600px',
            },
            dialogDetailsDuplicate: {
                title: '课程重复',
                dialogVisible: false,
                width: '750px',
            },
            dialogExportPdf: {
                title: '导出课程表',
                dialogVisible: false,
                width: '750px',
                height: ''
            },
            duplicate: '1',
            checkClassPatrolList: [],
            classPatrolList: [],
            tourDataList: [],
            showOption: true,
            classAndTeacher:{
                className:"",
                teacherName:''
            },
            comPointer: 'detail',
            dayDetail: false,
            repeatClassDisabled: false,
            courseScheduleDetail: {
                schoolId: '',
                productId: '',
                classArrangement: '',
                repeatType: '',
                repeatClass: '1', // 重复排课方式0不重复1每周重复2单周重复3双周重复(编辑)
                startScheduleTime: '',
                endScheduleTime: '',
                id: '', // 课程id
                weekAndNode:'',//周天+节日
                gradeClassName:'',//年级班级
                courseType: '', // 课表类型
                scheduleClassId: '', // 排课班级id（详情）
                classdata:'',//下拉列表班级数据
                scheduleClassName: '', // 排课班级名字（详情）
                scheduleClassName: '', // 排课班级名字（详情）
                subjectId: '', // 科目id（编辑）
                classTeacherId: '', // 上课教师id（编辑）
                supervisingTeacherId: '', // 督导教师id(编辑)
                scheduleDate: '',
                coursedate:'',
                scheduleTime: '', // 上课时间（详情）
                className: '', // 排课班级名称（详情）
                teacherName: '', // 上课教师（详情）
                subjectName: '', // 科目（详情）
                supervisingTeacherName: [],// 督导教师（详情）
                list: [{
                    week: '',
                    scheduleDate: '',
                    restTimeId: ''
                }],
                _time: '' // 详情显示
            },
            courseScheduleDetailRules: {
                repeatClass: [{ required: true, message: '请选择重复方式', trigger: 'change' }],
                coursedate: [{required: true, message: "请选择日期", trigger: ['blur', 'change'] }],
                subjectId: [
                    { required: true, message: '请选择科目', trigger: 'change' },
                ],
                classTeacherId: [
                    { required: true, message: '请选择上课教师', trigger: 'change' },
                ],
                // supervisingTeacherId: [
                //     { required: true, message: '请选择督导教师', trigger: ['blur', 'change'] },
                // ]
            },
            courseScheduleDetailSubjectsList: [],
            courseScheduleDetailTeachersList: [],
            courseScheduleDetailSupervisingTeachersList: [],
            /**
             * 指针数据
             * scheduleClassIdPointer 年级指针，新增课程时
             *
             * */
             scheduleClassIdPointer: '',
            /**
             * tourDetailsData 巡课详情数据
             */
             tourDetailsData: {},
             tips: [],
            /**
             * 预览PDF
             * 重复使用课程表列表组件，下载PDF状态
             *
             * */
            showDownloadPDFType: true,
            /**
             * 提前多久可以训课
             *
             * */
             advanceTime: 1000 * 60 * 30,
             importLoading: false, //是否显示进度条
              // 导入步骤
            importActive: 0,
            keyMap: {},
           // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [
            ],
              // 导入数据
            importForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                sameType: "1", // 1覆盖导入  2追加导入
            },
            // 导入弹窗配置
            importDialogObj: {
                title: "导入",
                dialogVisible: false,
                width: "900px",
            },
            tableJsonData: [],
            importInfo: {
                tips: "上传的文件必须包含：教师姓名（必填）、手机号（必填）、性别、所属部门（不填写默认可查看全部部门）",
                tips2: "直接上传已有的课表数据，下一步建立对应管理",
                downloadTemplate: "下载教师信息导入表",
                sameInfo: "覆盖导入：更新相同时间和相同班级的课程信息",
            },
            importSuccessData: 0,

            /**
             * 导入已有信息弹窗配置
             *
             * */
            importExistingDialogObj: {
                title: "导入已有档案信息",
                dialogVisible: false,
                width: "900px",
            },
            activeMore: 0, // 导入已有档案信息
            // 导入数据
            importMoreForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                archivesPersonType: "2", // 1学生档案  2教师档案
            },
            importMoreInfo: {
                tips: "选择上传模版内容，下载模版，根据模版内容填充数据，上传填写好的文件（如已有填写好的文件，可仅选择跟文档之中对应的模版，上传文件皆可）",
                options: [],
                value: "",
                downloadTemplate: "下载教师信息导入表",
                sameInfo: "覆盖导入：更新相同时间和相同班级的课程信息",
            },

            cascaderMutiData:{
                placeholder: "选择班级/级联选择",
                type: "cascader",
                key: 'organId',
                value: "",
                organType: "",
                list: [],
                cascaderProps: {
                    label: "name",
                    multiple: true,
                    value: "id",
                    checkStrictly: false,
                    emitPath: false
                    },
                    filter(data) {
                        return data.organType !== '1';
                    },
            },
            formData: {
                btnFormType: true,
                defaultclassid:'',
                data: [
                    /*{
                        type: "select",
                        key: "formId",
                        placeholder: "表单",
                        value: "",
                        list: [],
                    },*/
                    {
                        placeholder: "选择班级/级联选择",
                        type: "cascader",
                        key: 'organId',
                        value: "",
                        organType: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: false,
                            value: "id",
                            checkStrictly: false,
                            emitPath: false
                        },
                        filter(data) {
                            return data.organType !== '1';
                        },
                    }
                ],
            },
            selectChectArr:[],
            selectCourseIds:'',
            currentCourseIsTour:null
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            isTourClass: state => state.isTourClass,
        })
    },
    created () {
        this.init()
    },
    methods: {
        /**
         * @Description: 初始化函数
         * @DoWhat: 初始化函数
         * @UseScenarios: 初始化页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:42:25
         */
        init () {
            this.initPageAssignment()
            this.initPageData()
        },
        /**
         * @Description: 初始化页面赋值
         * @DoWhat: 初始化页面赋值
         * @UseScenarios: 初始化页面赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:44:16
         */
        initPageAssignment () {
            const _this = this;
            const courseScheduleTemp = new CourseScheduleTemp();

            this.queryList.schoolId = this.schoolId;
            this.queryList.productId = 'cloud_campus';

            this.filterQueryList.schoolId = this.schoolId;
            this.filterQueryList.productId = 'cloud_campus';
            // 排课星期请求赋值
            this.conditionList.schoolId = this.schoolId;
            this.conditionList.productId = 'cloud_campus';
            this.conditionList.isArrange = '1';

            // 新增课程/编辑
            this.courseScheduleDetail.schoolId = this.schoolId;
            this.courseScheduleDetail.productId = 'cloud_campus';
            this.courseScheduleDetail.classArrangement = '1';
            this.courseScheduleDetail.repeatType = '1';

            this.queryList.selectDay = courseScheduleTemp.getTimeDay(_this);
            console.log(this.queryList.selectDay,'this.queryList.selectDay')
        },
        /**
         * @Description: 初始化页面数据
         * @DoWhat: 初始化页面数据
         * @UseScenarios: 初始化页面数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:43:20
         */
        async initPageData () {
            this.loadingShow = true;
            this.getDataLoading = false;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            // 科目列表
            await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'科目列表')
                    this.courseScheduleDetailSubjectsList = res.data.data
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            // // 班级列表（日/周 新增课程的时候，下拉框）
            await teachingResearchCourseScheduleTempModel.getSchoolClassList({'schoolId': this.schoolId }).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data.list,'[schedulingClassesList]')
                    this.schedulingClassesList = res.data.data.list;
                    this.getFindClassList(this.schedulingClassesList)
                    this.assignmentOperations()
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            await teachingResearchCourseScheduleTempModel.getGradeClassListData({'schoolId': this.schoolId,jobScope:["1","2","3"]}).then((res) => {
                if (res.data.code == '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    let localData = formatTreeData(res.data.data.list, "id", "parentOrg")
                    let newlocaldata =[];
                    for (let index = 0; index < localData.length; index++) {
                        const item = localData[index];
                        if(item.children){
                            newlocaldata.push(item)
                        }
                    }
                    this.formData.data[index].list =newlocaldata
                    this.cascaderMutiData.list  = this.formData.data[index].list
                    let gradeClassList = res.data.data.list;
                    this.courseScheduleDetail.classdata=this.formData.data[index]
                    // console.log("获取的下拉列表王园园",this.courseScheduleDetail.classdata)
                    this.getClassTeacherIdForList(this.courseScheduleDetail.classdata.list)

                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 教师列表
            await teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    this.setDisabledOption(res.data.data);
                    console.log(res.data.data,'res.data.data 教师列表')
                    this.teacherList = res.data.data;
                    this.courseScheduleDetailTeachersList = res.data.data;
                    this.courseScheduleDetailSupervisingTeachersList = res.data.data;
                    console.log(this.teacherList,'this.teacherList教师数据')
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // 年级列表
            await teachingResearchCourseScheduleTempModel.getGradeOfTree({'schoolId': this.schoolId,
            "jobScope":[1,2,3]}).then((res) => {
                if (res.data.code == '200') {
                    this.gradeList = res.data.data;
                    console.log(res.data.data,'年级列表')
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })

            // await teachingResearchCourseScheduleTempModel.getGradeClassListData({'schoolId': this.schoolId,jobScope}).then((res) => {
            //     if (res.data.code == '200') {
            //         this.gradeList = res.data.data;
            //         console.log(res.data.data,'年级列表')
            //     } else if (res.data.msg){
            //         this.$message.error(res.data.msg)
            //     }
            // })
            // 请求上课时间
            await this.reClassTime()
            // await this.classnameandteacher()
        },

         /**
         * @Description: 获取最后一级的scheduleClassId
         * @DoWhat:获取最后一级的scheduleClassId
         * @UseScenarios: 获取最后一级的scheduleClassId
         * @Attention: 无
         * @Author: 王园园
         * @Date: 2023-08-28 10:43:19
         */
        getClassTeacherIdForList(list){
            let loallist  = list[0]
            if(loallist.children){
                this.getClassTeacherIdForList(loallist.children)
            }else{
                this.queryList.scheduleClassId = loallist.id
                this.formData.defaultclassid = loallist.id
            }
        },
        /**
         * @Description: 获取上课时间
         * @DoWhat: 获取上课时间
         * @UseScenarios: 获取上课时间
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-15 10:43:19
         */
         async reClassTime () {
            console.log(this.queryList,'请求课程表')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.conditionList.classId = this.queryList.scheduleClassId;
            this.conditionList.teacherId = this.queryList.classTeacherId;
            this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
            console.log(this.conditionList.gradeId,'请求课程表+++ this.conditionList')
            await teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList).then((res) => {
                this.courseList = [];
                if (res.data.code == '200') {
                    console.log(res.data,'上课星期数据')
                    res.data.data.forEach((item) => {
                        let obj = {
                            id: item.id,
                            label: `${item.name}`,
                            time: `${item.startTime}-${item.endTime}`,
                            starTime: item.startTime,
                            gradeId: item.gradeId,
                            gradeName:item.gradeName
                        };
                        this.courseList.push(obj)
                    })

                    this.changeQuery()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
            this.classnameandteacher()
         },
         async classnameandteacher(){
            // 获取班级和老师
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            let  postdic;
            if(this.queryList.courseType ==='1'){
                postdic={
                classId: this.queryList.scheduleClassId
                }
            }else if(this.queryList.courseType ==='2'){
                postdic={
                teacherId: this.queryList.classTeacherId
                }
            }else if(this.queryList.courseType ==='3'){
                postdic={
                classId: this.queryList.scheduleGradeId
                }
            }
            await teachingResearchCourseScheduleTempModel.getClassNameTeacherData(postdic).then((res) => {
                let teacherNames = res.data.data.teacherNames

                let teacherNameStr;
                if(this.queryList.courseType==='1'){
                    if(teacherNames.length>0){
                    teacherNameStr = teacherNames[0];
                    for (var i = 1; i < teacherNames.length; i++) {
                        teacherNameStr = teacherNameStr + '、' + teacherNames[i]
                    }
                   }else{
                    teacherNameStr='-'
                   }
                }else{
                    // console.log("么有尽量吗",data.teacherName)
                    teacherNameStr=res.data.data.teacherName
                }

                this.classAndTeacher={
                    className:res.data.data.className,
                    teacherName:teacherNameStr
                }
                if (this.queryList.courseType == '1') {
                    this.courseScheduleDetail.gradeClassName = res.data.className
                }
                // console.log(res.data,classAndTeacher,'数据-----老师')
            })
         },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
        /**
         * @Description: 获取页面数据
         * @DoWhat: 获取页面数据
         * @UseScenarios: 获取页面数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-11 16:45:09
         */
        getList() {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getCourseScheduleList().then((res) => {
                console.log(res, 'res')
            })
        },
        changeQuery() {

            if (this.queryList.courseType == '3') return;
            const _this = this;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            const courseScheduleTemp = new CourseScheduleTemp();

            this.getDataLoading = true;
            courseScheduleTemp.sortingQueryList(this.queryList, this.filterQueryList, _this)
            // if ( this.schedulingClassesList.length == 0 || this.teacherList.length == 0) {
            //     console.log(this.schedulingClassesList.length, this.teacherList.length,'length')
            //     this.$message.error('请先维护作息时间/班级/教师')
            //     return;
            // };
            teachingResearchCourseScheduleTempModel.getSchoolCourseList(this.filterQueryList).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data.data,'列表数据')
                    switch (_this.queryList.dayWeek) {
                        case 'day':
                            _this.dayList = [{label: '', child: []}];
                            courseScheduleTemp.getDayList(_this.filterQueryList, res.data.data, _this.dayList, _this.courseList, _this);
                            break;
                        case 'week':
                            _this.weekList = [];
                            courseScheduleTemp.getWeekList(_this.filterQueryList, res.data.data, _this.weekList, _this.courseList,_this);
                            break;
                        default:
                            break;
                    }
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                this.loadingShow = false;
                this.getDataLoading = false;

            })
        },
        /**
         * @Description: 选择课程类型
         * @DoWhat: 选择课程类型
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-04 10:14:38
         */
        handleChangeCourseType (e) {
            // console.log("选择课程类型", this.queryList)
            this.queryList.courseType = e;
            this.courseScheduleDetail.courseType = e;
            this.loding=false;


            switch (this.queryList.courseType) {
                case '1':
                this.conditionList.gradeId = ''; // 清除年级赋值
                this.queryList.scheduleGradeId = ''; // 清除年级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                // this.getFindClassList1 (this.schedulingClassesList)
                this.reClassTime ()
                    break;
                case '2':
                this.conditionList.gradeId = ''; // 清除年级赋值
                this.queryList.scheduleGradeId = ''; // 清除年级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                this.getFindTeacher(this.courseScheduleDetailTeachersList)// 教师课表，教师回显
                this.reClassTime ()

                    break;
                case '3':
                this.conditionList.teacherId = ''; // 清除年级赋值
                this.conditionList.classId ='';
                // this.queryList.scheduleClassId = ''; // 清除班级ID
                this.queryList.classTeacherId = '';  // 清除教师ID

                this.$nextTick(function(){
                this.getFindGrade(this.gradeList)
                console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                this.handlerGradeChange() // 根据年级获取班级，渲染左侧
                })
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.loding = true
            }, 500);


            this.classnameandteacher()


        },
        /**
         * @Description: 选择清空方式
         * @DoWhat: 选择清空方式
         * @UseScenarios: 选择清空方式
         * @Attention: 无
         * @Author: 王园园
         * @Date: 2023-07-27 11:12:20
         */
        emptyChange(value){
            this.emptydata.emptyvalue=value
        },
        /**
         * @Description: 选择日周
         * @DoWhat: 选择日周
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-04 11:12:20
         */
        handlerChangeStatus (data) {
            this.queryList.dayWeek = data.id;
            this.changeQuery()
        },
        /**
         * @Description: 添加课程表
         * @DoWhat: 添加课程表
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-04 11:44:31
         */
        handlerAddClick () {
            this.$emit('handlerAddClick')
        },
         // 选中年级班级
        // changeCascader(data) {
        //     this.$emit('changeCascader',data)
        //     data.ref.dropDownVisible = false;
        // },
        //清空课表选择日期
        emptydatePickerChange(value){
            console.log("日0000期",value)
            this.emptydata.emptydate=value
            this.courseScheduleDetail.startScheduleTime=value[0]
            this.courseScheduleDetail.endScheduleTime=value[1]
        },
        // 选择日期
        datePickerChange(value){
            console.log("----选择日期",this.emptydata.emptydate)
            this.courseScheduleDetail.startScheduleTime=value[0]
            this.courseScheduleDetail.endScheduleTime=value[1]
        },
        // 新增/编辑选择的重复方式
        handlerRepeatClassChange(val){
            this.courseScheduleDetail.repeatClass =val;
            // 如果是重复方式是-不重复就用点击的课程日期
            if(val == '0'){
                console.log("重复",val)
                this.courseScheduleDetail.startScheduleTime = this.courseScheduleDetail.scheduleTime
                this.courseScheduleDetail.endScheduleTime = this.courseScheduleDetail.scheduleTime

                this.courseScheduleDetail.coursedate = [this.courseScheduleDetail.scheduleTime,this.courseScheduleDetail.scheduleTime]
            }else{
                this.courseScheduleDetail.coursedate = ['','']
            }
        },
        /**
         * @Description: 选择日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023-07-21 9:45:44
         */

        //  datePickerBlur(value){
        //     console.log("wyy选择时间",value)
        //     this.courseScheduleDetail.startScheduleTime=value[0]
        //     this.courseScheduleDetail.endScheduleTime=value[1]
        // },
        /**
         * @Description: 选择日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023-07-21 10:45:44
         */
        changeCascader(data){
            console.log(data,data.ref.getCheckedNodes(),'===========');
            if(data.data.cascaderProps.multiple){
                let nodes =data.ref.getCheckedNodes()
                let nodeidstr=''
                if(nodes.length>0){
                    // nodeidstr = nodes[1].value;
                    for (var i = 0; i < nodes.length; i++) {
                        let nodeitem = nodes[i]

                        if (nodeitem.children.length==0) {
                            nodeidstr = nodeidstr + ',' + nodes[i].value
                        }

                    }
                }
                nodeidstr = nodeidstr.slice(1);
                this.emptydata.emptyclass=nodeidstr;
                this.emptydata.scheduleClassId = nodeidstr //多选
                this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;

            }else if (data.ref.getCheckedNodes()[0]) {
                let node;
                node = data.ref.getCheckedNodes()[0].data;
                this.queryList.scheduleClassId=node.id;
                this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
                this.reClassTime ()
                this.classnameandteacher()
            } else {
                this.queryList.scheduleClassId = ''
            }
            console.log("级联菜单id",this.queryList.scheduleClassId)
        },
        /**
         * @Description: 批量删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * changeCheck复选方法
         * onceDelCourse 单个删除
         * @Date: 2023-07-20 9:45:44
         */
         changeCheck(data){
            this.selectCourseIds=""
            if(data.item.checkState){
                this.selectChectArr.push(data.item)
            }else{
                for (let index = 0; index < this.selectChectArr.length; index++) {
                    const foritem = this.selectChectArr[index];
                    if(foritem.id == data.item.id){
                        this.selectChectArr.splice(index, 1)
                        break;
                    }
                }
            }
           if(this.selectChectArr.length>0){
            this.selectCourseIds = this.selectChectArr[0].id;
            for (var i = 1; i < this.selectChectArr.length; i++) {
              this.selectCourseIds = this.selectCourseIds + ',' + this.selectChectArr[i].id
            }
           }
        //   console.log("数组--->>",this.selectChectArr,this.selectCourseIds)
         },
         onceDelCourse(data){
            // 单删
            const h = this.$createElement;
            this.$confirm("提示", {
                title: "删除提示",
                message: h("div", [
                h("p", "删除后不能恢复"),
                h("p", "您确定删除当前课程吗?"),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                console.log("测试单次删除",data.item.id)
                this.selectCourseIds=data.item.id
                if(data.item.isTourClass){
                    this.$message.error("已设置固定巡课课程，不可删除。")
                    return
                }
                this.beacheDelCourse()

            })
            .catch(() => {
                // 取消
            });
           console.log("删除课程")
         },
        batchDeleteClick(){


console.log("删除第一个之后",this.selectCourseIds)

            if (this.selectCourseIds==="") {
                    this.$message.error("请先选择要删除的课程吧")
                    return
                }
            console.log("批量删除")
            const hm = this.$createElement;
            this.$confirm("提示", {
                title: "删除提示",
                message: hm("div", [
                hm("p", "删除后不能恢复"),
                hm("p", "您确定删除所选课程吗?"),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                console.log("测试批量删除")
                if (this.selectCourseIds==="") {
                    this.$message.error("请先选择要删除的课程吧")
                    return
                }
                this.beacheDelCourse()
            })
            .catch(() => {
                // 取消
            });
        },
        beacheDelCourse(){
            console.log(this.selectCourseIds)
            const _this = this;
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.getDataLoading = true;
            let postDic={
                ids:this.selectCourseIds,
            }
            teachingResearchCourseScheduleTempModel.batchDelCourseData(postDic).then((res) => {
                console.log("批量删除课程",res)
                if (res.data.code == '200') {

                    if(this.queryList.courseType ==='3'){
                        this.handlerGradeChange() // 根据年级获取班级，渲染左侧

                    }else{
                        this.changeQuery()

                    }
                    this.selectCourseIds=""
                    this.selectChectArr=[]
                    this.$message.success(res.data.msg)
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                this.loadingShow = false;
                this.getDataLoading = false;
            })
         },
        /**
         * @Description: 清空课表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023-07-20 9:45:44
         */
        emptyTableClick(){
            console.log("清空课表")

            this.emptydata.emptyclass = ''
            this.emptydata.emptyvalue='1' //清空课表默认全部清空
            this.courseScheduleDetail.startScheduleTime=''
            this.courseScheduleDetail.endScheduleTime=''
            this.dialogDetailsEmpty.dialogVisible=true;
        },
        /**
         * @Description: 清空课表弹框增加是否有固定课程校验
         * @Author: mrz
         * @Date: 2024-08-29 16:50:34
         */
        sureClear(){
            this.$refs['emptydata'].validate((valid) => {
                console.log("状态是什么",valid)
                if (valid) {
                    const _this = this;
                    const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                    this.getDataLoading = true;

                    let postDic={
                    productId:'cloud_campus',
                    schoolId:this.schoolId,
                    deletestate:this.emptydata.emptyvalue,
                    scheduleClassId:this.emptydata.scheduleClassId,
                    startScheduleTime:this.courseScheduleDetail.startScheduleTime,
                    endScheduleTime:this.courseScheduleDetail.endScheduleTime
                }
                teachingResearchCourseScheduleTempModel.getEmptyBeforeCheck(postDic).then(res => {
                    if(res.data.code == 200){
                        // 调用删除接口
                        teachingResearchCourseScheduleTempModel.emptyCourseTableData(postDic).then((res) => {
                            if (res.data.code == '200') {
                                this.$refs.J_cascader.clearnode()
                                this.$refs.J_datePicker.cleardate()
                                // 清空级联复选与日期
                                this.dialogDetailsEmpty.dialogVisible=false;
                                console.log("清空课表",res)
                                if(this.queryList.courseType === '3'){
                                    this.conditionList.teacherId = ''; // 清除年级赋值
                                    this.conditionList.classId ='';
                                    this.queryList.scheduleClassId = ''; // 清除班级ID
                                    this.queryList.classTeacherId = '';  // 清除教师ID
                                    this.getFindGrade(this.gradeList)
                                    console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                                    this.handlerGradeChange() // 根据年级获取班级，渲染左侧
                                }else{
                                    this.changeQuery()
                                }
                                // this.$message.success(res.data.msg)
                                this.$message.success('清空成功')
                            } else if (res.data.msg){
                                this.$message.error(res.data.msg)
                            }
                            this.loadingShow = false;
                            this.getDataLoading = false;
                            })
                    }else{
                        const hm = this.$createElement;
                        this.$confirm("提示", {
                            title: "删除提示",
                            message: hm("div", [
                            hm("p", "除设置固定巡课的课程，已选择课表中其他课程将全部清空，且不能恢复，是否继续?")
                            ]),
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                        .then(() => {
                            // 删除接口
                            teachingResearchCourseScheduleTempModel.emptyCourseTableData(postDic).then((res) => {
                            if (res.data.code == '200') {
                                this.$refs.J_cascader.clearnode()
                                this.$refs.J_datePicker.cleardate()
                                // 清空级联复选与日期
                                this.dialogDetailsEmpty.dialogVisible=false;
                                console.log("清空课表",res)
                                if(this.queryList.courseType === '3'){
                                    this.conditionList.teacherId = ''; // 清除年级赋值
                                    this.conditionList.classId ='';
                                    this.queryList.scheduleClassId = ''; // 清除班级ID
                                    this.queryList.classTeacherId = '';  // 清除教师ID
                                    this.getFindGrade(this.gradeList)
                                    console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                                    this.handlerGradeChange() // 根据年级获取班级，渲染左侧
                                }else{
                                    this.changeQuery()
                                }
                                // this.$message.success(res.data.msg)
                                this.$message.success('清空成功')
                            } else if (res.data.msg){
                                this.$message.error(res.data.msg)
                            }
                            this.loadingShow = false;
                            this.getDataLoading = false;
                            })
                        })
                        .catch(() => {
                            // 取消
                        });
                    }
                    return
                })
                } else {
                    console.log('error submit!!');
                    return;
                }
            });
        },
        /**
         * @Description: 课表导入
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王园园
         * @Date: 2023-07-20 9:45:44
         */
         handlerImportClick(){
            this.activeMore=0;
            this.importActive = 0;
            this.importDialogObj.dialogVisible = true;
            console.log("课表导入")
         },

         /**
         * @Author: 王园园
         * @Date: 2023-07-20 11:00:44
         * downloadImportTemplate  下载课程表模板
         * importUploadRemove      删除已上传文件
         * importUploadChange      已上传文件赋值
         * handleImportNext        下一步
         * handleImportBefore      上一步
         * handleImportClose       导入弹窗关闭
         * handleImportSave        保存导入信息
         * importSelChange         表头字段下拉选择
         * getNewData              获取新生成的数据
         * readFile                读取文件
         */
         downloadImportTemplate() {
            this._get(
                "/school/schoolCourse/template"
            ).then((res) => {
                console.log("下载模板",res)
                if (res.data.code === "200") {
                    downloadFile(
                        {
                            url: res.data.data,
                            method: "get",
                        },
                        () => {},
                        () => {},
                    );
                }
            });

            if(this.queryList.courseType === '3'){
                this.conditionList.teacherId = ''; // 清除年级赋值
                this.conditionList.classId ='';
                this.queryList.scheduleClassId = ''; // 清除班级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                this.getFindGrade(this.gradeList)
                console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                this.handlerGradeChange() // 根据年级获取班级，渲染左侧

            }else{
                this.reClassTime()
            }

        },
        importUploadRemove() {
            console.log("移除文件")
            this.importForm.file = "";
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw;
        },
        handleImportNext() {
            if (this.importForm.file === "") {
                return this.$message.warning("上传已有的课表数据");
            }
            this.importActive++;
            console.log(this.importActive, "this.importActive");
        },
        handleImportBefore() {
            this.importActive--;
        },
        handleImportClose(){
            // this.importForm.file = "";
            // this.$importRef.upload.clearFiles();
            // this.importDialogObj.dialogVisible = false;



            this.importDialogObj.dialogVisible = false;
			this.showDownload = true;
			this.importActive = 0;
			this.importForm.file = "";
			this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles();
			this.$refs.importRef.$refs.importRef.$data.fileName = "";



            if(this.queryList.courseType ==='3'){
                this.conditionList.teacherId = ''; // 清除年级赋值
                this.conditionList.classId ='';
                this.queryList.scheduleClassId = ''; // 清除班级ID
                this.queryList.classTeacherId = '';  // 清除教师ID
                this.getFindGrade(this.gradeList)
                console.log(this.queryList.scheduleGradeId,'this.queryList.scheduleGradeId')
                this.handlerGradeChange() // 根据年级获取班级，渲染左侧
            }else{
                this.reClassTime()
            }


        },
        handleImportSave() {
            this.importLoading = true;
            // console.log("1111111111111111", this.importLoading);
            const requireList = this.systemExcelHeader.filter(
                (i) => i.required === true,
            );
            const index = requireList.findIndex(
                (item) => this.keyMap[item.label] === "",
            );
            if (index > -1) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            // const testModel = new TestModel();
            console.log("王园园导入接口",this.importForm.errType,this.importForm.sameType)

            const courseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            // const file = this.getNewData();
            const file = this.importForm.file;
            let formData = new FormData();
            formData.append("file", file);

            // formData.append("schoolId", this.listQuery.schoolId);
            // formData.append("menuId", this.$route.meta.menuId);
            formData.append("errType", this.importForm.errType);
            formData.append("nextType", this.importForm.sameType);
            courseScheduleTempModel
                .courseImportInfo(formData)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.importSuccessData = res.data.data.count;
                        if(res.data.data.errorId!=""){
                           this.downloadErrorList(res.data.data.errorId);
                        }else{
                            this.importActive++;
                        }
                    } else {
                        const errMsg = res.data ? res.data.msg : '';
                        errMsg && this.$message.error(errMsg);

                        if (!errMsg) {
                            this.$message.error('上传失败，请稍联系管理员～');
                        }
                    }
                })
                .catch(err => {
                    this.$message.error('上传错误，请稍后再试～');
                })
                .finally(() => {
                    this.importLoading = false;
                });
        },
        downloadErrorList(errorid) {
            const h = this.$createElement;
            this.$confirm("提示", {
                title: "日志提示",
                message: h("div", [
                h("p", "您要下载导入错误信息表吗?"),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                downloadFile({
                    url: '/school/schoolCourse/downloadErrorExcel',
                    method: 'post',
                    fileName: '导入错误信息表.xlsx',
                    form: {errorId: errorid}
                }, () => {
                }, () => {

                })
            })
            .catch(() => {
                // 取消
            });
        },
        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let index = Object.keys(this.keyMap).findIndex(
                    (k) => this.keyMap[k] === item.label,
                );
                item.disabled = index > -1;
            });
        },

        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = (ev) => {
                    resolve(ev.target.result);
                };
            });
        },
        /**
         * @Description: 导出PDF
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: wdc
         * @Date: 2023-01-31 14:45:44
         */
         handlerExportClick () {
            const _domInfo = document.getElementById('pdfPreviewDom1').getBoundingClientRect();

            this.dialogExportPdf.width = _domInfo.width + 'px';
            this.dialogExportPdf.dialogVisible = true;
            // this.dialogExportPdf.height = this.courseList.length * 83 + 146 + 'px';
            console.log('PDF',)
         },
        /**
         * @Description: 表格左上角是否巡课
         * @DoWhat: 筛选是否巡课
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-04 14:48:50
         */
         handlerClassPatrolClick () {
            if(!this.queryList.isTourClass) {
                this.queryList.isTourClass = '0'
                this.filterQueryList.isTourClass = '0'
            } else {
                this.queryList.isTourClass = ''
                this.filterQueryList.isTourClass = ''
            }
         },
        /**
         * @Description: 删除全部课程
         * @DoWhat: 删除 日 的全部课程
         * @UseScenarios: 删除 日 的全部课程
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-07 09:12:07
         */
         delAllCourses (data) {
            console.log(data, '删除全部')
            this.deleteComposite ({id: data.data.id, deleteType: data.type, courseType: this.queryList.courseType })
         },
         /**
          * @Description: 编辑课程
          * @DoWhat: 编辑 日 的课程
          * @UseScenarios: 编辑 日 的课程
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2022-11-07 10:26:41
          */
         edit (data) {
            console.log(data, '这是编辑弹框data', this.courseScheduleDetail, this.courseList)
            Object.keys(data).forEach((key) => {
                Object.keys(this.courseScheduleDetail).forEach((subKey) => {
                    if (key === subKey) {
                        this.courseScheduleDetail[key] = data[key];
                    }
                })
            })

            if (this.queryList.courseType == '3') {
                this.courseScheduleDetail._timer = data.data.time;
            }

            if(typeof this.courseScheduleDetail.supervisingTeacherId === 'string') {
                this.courseScheduleDetail.supervisingTeacherId = this.courseScheduleDetail.supervisingTeacherId.split(',');
            }

            if(data.repeatClass != '0') {
                this.courseScheduleDetail.list[0].week = this.$moment(this.courseScheduleDetail.scheduleTime,'YYYY-MM-DD').format('E')
            } else {
                this.courseScheduleDetail.list[0].scheduleDate = this.courseScheduleDetail.scheduleTime;
            }

            this.courseScheduleDetail.repeatClass = this.courseScheduleDetail.repeatClass + '';

            if (this.queryList.courseType == '3') {
                // 年级
                // this.courseScheduleDetail._timer = data.data.time;
                // this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            } else {
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data._uuid;
            }

            console.log(this.courseScheduleDetail,'this.courseScheduleDetail---==')

            this.courseScheduleDetail.coursedate=[this.courseScheduleDetail.startScheduleTime,this.courseScheduleDetail.endScheduleTime]

            this.dialogDetailsEditor.title = '编辑';
            this.comPointer = 'edit'
            this.repeatClassDisabled = true;
            this.dayDetail = false;
            this.dialogDetailsEditor.dialogVisible = true;
         },
         /**
          * @Description:
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author:
          * @Date: 2022-11-16 21:54:56
          */
          dataAddCourse (detailDaydata) {
            console.log(detailDaydata,'日 新增课程')


            let data = detailDaydata.data
            let subject = detailDaydata.daylist.child[detailDaydata.idx]
            if(this.queryList.courseType === 2){
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+'作息'
            }else{
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+subject._uuName
            }
            this.courseScheduleDetail._time = subject._time

            this.courseScheduleDetail.id = ''; //data._uuid
            this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
            this.courseScheduleDetail.scheduleTime = data._day;


            // this.courseScheduleDetail.scheduleTime = "888";

            this.courseScheduleDetail.scheduleDate = data._day;
            this.courseScheduleDetail.startScheduleTime = data._day;
            this.courseScheduleDetail.endScheduleTime = data._day;

            this.getClassName(this.schedulingClassesList) // 获取班级名称

            this.courseScheduleDetail.list[0].scheduleDate = data._day;
            this.courseScheduleDetail.list[0].restTimeId = data._uuid;
            this.courseScheduleDetail.list[0].week = data._week;

            // 根据左侧课程表 显示选择的班级
            this.getNewClassDropDown(this.schedulingClassesList, data._gradeId)
            this.schedulingClassesList.forEach((item) => {
                if (data._gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }
            })
            console.log(this.schedulingClassesListFilter,'班级列表')
            if (this.queryList.courseType == '1') {
                this.courseScheduleDetail.scheduleClassId = this.queryList.scheduleClassId;
            } else {
                this.courseScheduleDetail.scheduleClassId = this.getScheduleClassName(this.schedulingClassesListFilter)
            }
            if (this.queryList.courseType == '3') {
                this.courseScheduleDetail._timer = data.data.time;
            }


            console.log(this.courseScheduleDetail,'周--添加课程')
            this.comPointer = '';
            this.dayDetail = false;
            this.repeatClassDisabled = false;
            this.dialogDetailsEditor.title = '新增课程';
            this.dialogDetailsEditor.dialogVisible = true;
          },
         /**
          * @Description: 删除课程
          * @DoWhat: 删除 日 课程
          * @UseScenarios: 删除 日 课程
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2022-11-07 10:26:46
          */
         del (data) {
            console.log('del', data)
            this.deleteComposite ({id: data.data.id, deleteType: data.type, courseType: this.queryList.courseType })
         },
        /**
         * @Description: 日，详情
         * @DoWhat: 日，详情
         * @UseScenarios: 日，详情
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 14:56:42
         */
         detailDay (detailDaydata) {
            console.log(detailDaydata,'日，详情')
            let data = detailDaydata.data
               // 循环级部/年级/班级
            this.getNewClassDropDown(this.schedulingClassesList, data._gradeId)

            let subject = detailDaydata.daylist.child[detailDaydata.idx]
            if(this.queryList.courseType === 2){
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+'作息'
            }else{
                this.courseScheduleDetail.weekAndNode  =detailDaydata.daylist.label.substr(-2) +" | "+subject._uuName
            }
            this.courseScheduleDetail._time = subject._time
            this.courseScheduleDetail.repeatClass = data.repeatClass+''
            this.courseScheduleDetail.subjectId = data.subjectId

            if(data.startScheduleTime === null || data.startScheduleTime ==='null' || data.startScheduleTime ===''){
                this.courseScheduleDetail.coursedate = [data.scheduleTime,data.scheduleTime]
                console.log("周三---1111",this.courseScheduleDetail.coursedate)


            }else{

                this.courseScheduleDetail.coursedate = [data.startScheduleTime,data.endScheduleTime]
                console.log("周三---",this.courseScheduleDetail.coursedate)
            }





            this.courseScheduleDetail.classTeacherId = data.classTeacherId
            Object.keys(data).forEach((key) => {
                Object.keys(this.courseScheduleDetail).forEach((subKey) => {
                    if (key === subKey) {
                        this.courseScheduleDetail[key] = data[key];
                    }
                })
            })
            if(data.repeatClass != '0') {
                this.courseScheduleDetail.list[0].week = this.$moment(this.courseScheduleDetail.scheduleTime,'YYYY-MM-DD').format('E')
            } else {
                this.courseScheduleDetail.list[0].scheduleDate = this.courseScheduleDetail.scheduleTime;
            }
            this.courseScheduleDetail.repeatClass = this.courseScheduleDetail.repeatClass + '';
            if (this.queryList.courseType == '3') {
                // 年级
                // this.courseScheduleDetail._timer = data.data.time;
                // this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            } else {
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data._uuid;
            }
            this.courseScheduleDetail.supervisingTeacherId = data.supervisingTeacherId.split(',');
            this.comPointer = 'detail';
            this.dayDetail = true;
            this.dialogDetailsEditor.title = '详情';
            this.dialogDetailsEditor.dialogVisible = true;
         },
        /**
         * @Description: 清除新增/编辑弹窗数据
         * @DoWhat: 清除新增/编辑弹窗数据
         * @UseScenarios: 清除新增/编辑弹窗数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 15:19:07
         */
         clearPopUpEditingDetailsData () {
            this.courseScheduleDetail.className = '';
            if(this.queryList.courseType !='2'){
                this.courseScheduleDetail.classTeacherId = '';
            }
            // this.courseScheduleDetail.courseType = '';
            this.courseScheduleDetail.id = '';
            // this.courseScheduleDetail.repeatClass = '0';
            this.courseScheduleDetail.repeatClass = '1';
            this.courseScheduleDetail.coursedate = ''; //初始化日期
            this.courseScheduleDetail.list = [{ restTimeId: '', scheduleDate: '', week: '' }];
            // this.courseScheduleDetail.scheduleClassId = '';
            this.courseScheduleDetail.scheduleDate = '';
            this.courseScheduleDetail.scheduleTime = '';
            this.courseScheduleDetail.subjectId = '';
            this.courseScheduleDetail.subjectName = '';
            this.courseScheduleDetail.supervisingTeacherId = '';
            this.courseScheduleDetail.supervisingTeacherName = [];
            this.courseScheduleDetail.teacherName = '';
            this.courseScheduleDetail.startScheduleTime = '';
            this.courseScheduleDetail.endScheduleTime = '';
            this.courseScheduleDetail.repeatType = '1';
            this.dayDetail = false;
            this.comPointer = 'detail';
            this.repeatClassDisabled = false;
         },
        /**
         * @Description: 周添加课程
         * @DoWhat: 周添加课程
         * @UseScenarios: 周添加课程
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 10:58:46
         */
         addWeekCourseSchedule (data) {
            // console.log(data,'data++', this.scheduleClassIdPointer, this.schedulingClassesList)
            console.log(this.schedulingClassesListFilter, this.schedulingClassesList, '班级列表')
            console.log("点击的内容",data)
            this.courseScheduleDetail.id = '';

            if(this.queryList.type === 2){
                this.courseScheduleDetail.weekAndNode  =data.data.label.slice(0,2) +" | "+ '作息'
            }else{
                this.courseScheduleDetail.weekAndNode  =data.data.label.slice(0,2) +" | "+ data.item._uuName
            }
            // handleChangeCourseType
            // this.courseScheduleDetail.scheduleTime =data.item._time
            this.courseScheduleDetail.scheduleTime = data.item._day;
            this.courseScheduleDetail.repeatClass = '1';
            this.courseScheduleDetail._time = data.item._time;
            this.courseScheduleDetail.scheduleDate = data.item._day;
            // this.courseScheduleDetail.startScheduleTime = data.item._day;
            // this.courseScheduleDetail.endScheduleTime = data.item._day;

            // this.getClassName(this.schedulingClassesList) // 获取班级名称
            this.courseScheduleDetail.list[0].scheduleDate = data.item._day;
            this.courseScheduleDetail.list[0].week = data.data.id + 1 + '';

            if (this.queryList.courseType == '3') {
                // 年级
                // this.courseScheduleDetail.className = data.item._uuName;
                this.courseScheduleDetail.gradeClassName = data.item._uuName
                this.courseScheduleDetail._timer = data.data.time;
                this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            } else{
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data.item._uuid;
            }
            console.log(this.courseScheduleDetail.className,'this.courseScheduleDetail最终个数据')

            console.log(this.courseScheduleDetail, this.schedulingClassesList,'schedulingClassesList')
            // 根据左侧课程表 显示选择的班级
            // 循环级部/年级/班级
            this.getNewClassDropDown(this.schedulingClassesList, data.item._gradeId)

            // 年级数据
            this.schedulingClassesList.forEach((item) => {
                if (data.item._gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }
            })
            console.log(this.schedulingClassesListFilter, '年级列表')
            switch(this.queryList.courseType) {
                case '1':
                    this.courseScheduleDetail.scheduleClassId = this.queryList.scheduleClassId;
                    break;
                case '2':
                    // 注释的这个方法已经废弃，会导致班级课表为空
                    this.courseScheduleDetail.scheduleClassId = this.getScheduleClassName(this.schedulingClassesListFilter)
                    // this.courseScheduleDetail.scheduleClassId = this.queryList.scheduleClassId;
                    // this.courseScheduleDetail.scheduleClassId = '';

                    break;
                case '3':
                    this.courseScheduleDetail.scheduleClassId = data.item._class;
                    break;
                default:
                    break;
            }

            // this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
        //    this.courseScheduleDetail.coursedate=['','']
           this.courseScheduleDetail.subjectId=''

           if (this.queryList.courseType != '2') {
            //教师课表要回显上课教师所以不能置空
            this.courseScheduleDetail.classTeacherId=''
           }
            this.courseScheduleDetail.supervisingTeacherId = '';//点击新增的时候清空督导教师
            // this.courseScheduleDetailSupervisingTeachersList =[]
            this.comPointer = '';
            this.dayDetail = false;
            this.repeatClassDisabled = false;
            this.dialogDetailsEditor.title = '新增课程';
            this.dialogDetailsEditor.dialogVisible = true;
            // this.$nextTick(() => {
            //     this.$refs.courseScheduleDetailWrapper.resetFields()
            // })
         },
         /**
          * @Description: 获取班级列表
          * @DoWhat: 获取班级列表
          * @UseScenarios:
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2023-02-14 18:36:39
          * @param {*} list
          */
          getNewClassDropDown (list, gradeId) {
            console.log(list,'list')
            list.forEach((item) => {
                if (gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }

                if (item.child && item.child.length > 0) {
                    this.getNewClassDropDown (item.child, gradeId)
                }
            })
            // this.schedulingClassesList.forEach((item) => {
            //     if (data.item._gradeId == item.id) {
            //         this.schedulingClassesListFilter = [item];
            //     }
            // })
          },
         getScheduleClassName (list) {
            let str = '';
            for (let i = 0; i < list.length; i++) {
                console.log(list[i],'list[i].organType')
                if (list[i].organType == '4') {
                    str = list[i].id;
                    return str;
                }
                if (list[i].child && list[i].child.length > 0) {
                    return this.getScheduleClassName (list[i].child, str)
                }
            }

         },
         getClassName (list) {
            list.forEach((item) => {
                if (this.scheduleClassIdPointer == item.id) {
                    this.courseScheduleDetail.className = item.name;
                }
                if (item.child.length > 0) {
                    return this.getClassName(item.child)
                }
            })
         },
        /**
         * @Description: 点击进入详情
         * @DoWhat: 点击进入详情
         * @UseScenarios: 点击进入详情
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 09:46:34
         */
         goToDetail (data) {
            this.currentCourseIsTour = data.item.isTourClass
            console.log('goToDetail事件----巡课状态',data.item.isTourClass)
              // 循环级部/年级/班级
              this.getNewClassDropDown(this.schedulingClassesList, data.item._gradeId)

            Object.keys(data.item).forEach((key) => {
                Object.keys(this.courseScheduleDetail).forEach((subKey) => {
                    if (key === subKey) {
                        this.courseScheduleDetail[key] = data.item[key];
                    }
                })
            })

            if (this.queryList.courseType == '3') {
                this.courseScheduleDetail._timer = data.data.time;
            }

            if(typeof this.courseScheduleDetail.supervisingTeacherId === 'string') {
                this.courseScheduleDetail.supervisingTeacherId = this.courseScheduleDetail.supervisingTeacherId.split(',');
            }

            if(data.item.repeatClass != '0') {
                this.courseScheduleDetail.list[0].week = data.index + 1 + '';
            } else {
                this.courseScheduleDetail.list[0].scheduleDate = this.courseScheduleDetail.scheduleTime;
            }

            if (this.queryList.courseType == '3') {
                // 年级
                // this.courseScheduleDetail._timer = data.data.time;
                this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            }else {
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data.item._uuid;
            }

            this.courseScheduleDetail.repeatClass = this.courseScheduleDetail.repeatClass + '';
            if (this.courseScheduleDetail.startScheduleTime === null || this.courseScheduleDetail.startScheduleTime=='null' || this.courseScheduleDetail.startScheduleTime==='') {
            this.courseScheduleDetail.startScheduleTime = this.courseScheduleDetail.scheduleTime
            this.courseScheduleDetail.endScheduleTime = this.courseScheduleDetail.scheduleTime
        }
            this.courseScheduleDetail.weekAndNode=data.data.label.slice(0,2) +" | "+ data.item._uuName
            this.courseScheduleDetail.coursedate=[this.courseScheduleDetail.startScheduleTime,this.courseScheduleDetail.endScheduleTime]

            console.log(data.item._timestamp >= new Date().getTime(),'data.item._timestamp >= new Date().getTime()')

            this.dayDetail = !(data.item._timestamp >= new Date().getTime());
            console.log(this.courseScheduleDetail, 'this.courseScheduleDetail')




            this.comPointer = 'detail'
            this.dialogDetailsEditor.title = '详情';
            this.dialogDetailsEditor.dialogVisible = true;

         },
        /**
         * @Description: 跳转巡课页面 （日）
         * @DoWhat: 跳转巡课页面
         * @UseScenarios: 跳转巡课页面
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-18 10:04:23
         */
         tourDetails (data) {
            console.log(data, '跳转巡课页面',this.tourDetailsData.supervisingTeacherId)
            this.classPatrolList = [];
            this.tourDataList = [];

            if (data.tourClassMap) {

                Object.keys(data).forEach((key) => {
                    this.tourDetailsData[key] = data[key]
                })
                this.tourDetailsData['type'] = 'CourseSchedule';

                let supervisingTeacherName = data.supervisingTeacherName,
                    tourClassMap = data.tourClassMap,
                    supervisingTeacherId = data.supervisingTeacherId.split(','),
                    mapTeacherNameInfo = [],
                    removeTeacherNameInfo = [];


                Object.keys(tourClassMap).forEach((item, index) => {
                    removeTeacherNameInfo.push(item)
                })

                this.findMapData(this.courseScheduleDetailTeachersList, supervisingTeacherId, mapTeacherNameInfo)
                mapTeacherNameInfo.forEach((item) => {
                    removeTeacherNameInfo.forEach((subItem) => {
                        if (item.id == subItem) {
                            supervisingTeacherName.forEach((pIt, pId) => {
                                if (item.name == pIt) {
                                    supervisingTeacherName.splice(pId, 1);
                                }
                            })
                        }
                    })
                })
                console.log(supervisingTeacherName,'supervisingTeacherName')
                // 拼装完成待巡课数据
                supervisingTeacherName.forEach((item) => {
                    let obj = {
                        label: item,
                        value: item
                    }
                    this.classPatrolList.push(obj);
                })
                this.classPatrolList = this.deduplication(this.classPatrolList);

                // 正在巡课/ 完成巡课数据
                let tourData = mapTeacherNameInfo.filter((item) => {
                    return removeTeacherNameInfo.indexOf(item.id) > -1;
                })
                tourData.forEach((item) => {
                    Object.keys(tourClassMap).forEach((subItem, subIndex) => {
                        if (item.id == subItem) {
                            console.log(tourClassMap[subItem],'subIndex')
                            item = Object.assign(item, tourClassMap[subItem])
                        }
                    })
                })

                tourData = this.deduplication(tourData);
                this.tourDataList = tourData;
                this.dialogClassPatrol.dialogVisible = true;
                console.log(this.classPatrolList, tourData,'supervisingTeacherName渲染数据')
            } else {
                Object.keys(data).forEach((key) => {
                    this.tourDetailsData[key] = data[key]
                })
                this.tourDetailsData['type'] = 'CourseSchedule';

                let supervisingTeacherName = data.supervisingTeacherName;
                supervisingTeacherName.forEach((item) => {
                    let obj = {
                        label: item,
                        value: item
                    }
                    this.classPatrolList.push(obj)
                })
                console.log(this.tourDetailsData,'this.tourDetailsData')
                this.dialogClassPatrol.dialogVisible = true;
            }
         },
        /**
         * @Description: 点击巡课/未巡课， dialogClassPatrol 弹窗弹出  （周）
         * @DoWhat: 点击巡课/未巡课
         * @UseScenarios: 点击巡课/未巡课
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 09:34:24
         */
         lectureSetting (data) {
            console.log('点击未巡课：',data)
            // 未巡课跳转新增巡课记录页面-非巡课教师目前不显示未巡课字段，不可点击
            // 已巡课跳转巡课记录列表页
            if(data.item.isTourClass == '0'){
                sessionStorage.setItem("patrol-schedule","true")
                this.$router.push({
                    name: "SEduClassroomPatrolRecord",
                });
            }else if(data.item.isTourClass == '1'){
                this.$router.push({
                    name: "SEduClassroomPatrolRecord",
                })
            }

            console.log(data, data.item,'巡课', this.courseScheduleDetailSupervisingTeachersList)
            console.log(data.item.isTourClass,'isTourClass')
            this.classPatrolList = [];
            this.tourDataList = [];

            // if (this.queryList.courseType == '3') {
            //     // 年级
            //     data.item._uuid = data.item.restTimeId;
            // }

            // if (data.item.tourClassMap) { // 有继续巡课的数据 有详情数据
            //     Object.keys(data.item).forEach((key) => {
            //         this.tourDetailsData[key] = data.item[key]
            //     })
            //     this.tourDetailsData['type'] = 'CourseSchedule';

            //     let supervisingTeacherName = JSON.parse(JSON.stringify(data.item.supervisingTeacherName)),
            //         tourClassMap = JSON.parse(JSON.stringify(data.item.tourClassMap)),
            //         supervisingTeacherId = JSON.parse(JSON.stringify(data.item.supervisingTeacherId.split(','))),
            //         mapTeacherNameInfo = [],
            //         removeTeacherNameInfo = [];

            //      Object.keys(tourClassMap).forEach((item, index) => {
            //         removeTeacherNameInfo.push(item)
            //      })

            //     this.findMapData(this.courseScheduleDetailTeachersList, supervisingTeacherId, mapTeacherNameInfo)

            //     console.log(mapTeacherNameInfo, removeTeacherNameInfo, '总数据')

            //     mapTeacherNameInfo.forEach((item) => {
            //         removeTeacherNameInfo.forEach((subItem) => {
            //             if (item.id == subItem) {
            //                 supervisingTeacherName.forEach((pIt, pId) => {
            //                     if (item.name == pIt) {
            //                         supervisingTeacherName.splice(pId, 1);
            //                     }
            //                 })
            //             }
            //         })
            //     })
            //     console.log(supervisingTeacherName,'supervisingTeacherName')
            //     // 拼装完成待巡课数据
            //     supervisingTeacherName.forEach((item) => {
            //         let obj = {
            //             label: item,
            //             value: item
            //         }
            //         this.classPatrolList.push(obj);
            //     })

            //     this.classPatrolList = this.deduplication1(this.classPatrolList)
            //     console.log(this.classPatrolList, '渲染111')
            //     // 正在巡课/ 完成巡课数据
            //     let tourData = mapTeacherNameInfo.filter((item) => {
            //         return removeTeacherNameInfo.indexOf(item.id) > -1;
            //     })
            //     tourData.forEach((item) => {
            //         Object.keys(tourClassMap).forEach((subItem, subIndex) => {
            //             if (item.id == subItem) {
            //                 console.log(tourClassMap[subItem],'subIndex')
            //                 item = Object.assign(item, tourClassMap[subItem])
            //             }
            //         })
            //     })
            //     tourData = this.deduplication(tourData)

            //     this.tourDataList = tourData;
            //     this.dialogClassPatrol.dialogVisible = true;
            //     console.log(this.classPatrolList, tourData,'supervisingTeacherName渲染数据')
            // } else { // 全部没有继续巡课

            //     Object.keys(data.item).forEach((key) => {
            //         this.tourDetailsData[key] = data.item[key]
            //     })
            //     this.tourDetailsData['type'] = 'CourseSchedule';

            //     let supervisingTeacherName = data.item.supervisingTeacherName;
            //     supervisingTeacherName.forEach((item) => {
            //         let obj = {
            //             label: item,
            //             value: item
            //         }
            //         this.classPatrolList.push(obj)
            //     })
            //     console.log(this.tourDetailsData, this.classPatrolList, 'this.tourDetailsData 渲染数据')
            //     this.dialogClassPatrol.dialogVisible = true;
            // }
        },
        findMapData (courseScheduleDetailTeachersList, supervisingTeacherId, mapTeacherNameInfo) {
            courseScheduleDetailTeachersList.forEach((item) => {
                supervisingTeacherId.forEach((subItem) => {
                    if (item.id == subItem) {
                        mapTeacherNameInfo.push(item)
                    }
                })

                if (item.children && item.children.length > 0) {
                    this.findMapData (item.children, supervisingTeacherId, mapTeacherNameInfo)
                }
            })
        },
        getTeach(supervisingTeacherList, supervisingTeacherId, selectT) {
            supervisingTeacherList.forEach((subItem) => {
                if (supervisingTeacherId == subItem.id) {
                    let obj = {
                        id: subItem.id,
                        teacherName: subItem.name
                    };

                    selectT.push(obj)
                }

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach(subItem.children, supervisingTeacherId, selectT)
                }
            })
        },
        getTeach1 (supervisingTeacherList, data, selectT) {
            supervisingTeacherList.forEach((subItem) => {
                data.forEach((item) => {
                    if (item.supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name
                        };

                        selectT.push(obj)
                    }
                })

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach1(subItem.children, data, selectT)
                }
            })
        },
        /**
         * @Description: 关闭巡课弹窗
         * @DoWhat: 关闭巡课弹窗
         * @UseScenarios:关闭弹窗
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 10:23:40
         */
         handleCloseClassPatrol () {
            this.dialogClassPatrol.dialogVisible = false;
         },
        /**
         * @Description: 点击弹窗巡课
         * @DoWhat: 点击弹窗巡课
         * @UseScenarios: 点击弹窗巡课
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 10:47:16
         */
         handlerGoClassPatrolClick () {
            if (this.checkClassPatrolList.length > 0) {
                console.log(this.checkClassPatrolList, 'checkClassPatrolList', this.tourDetailsData)
                let alreadySelectedTeachers = [],
                    selectT = [];
                let supervisingTeacherId =  this.tourDetailsData.supervisingTeacherId;

                if (typeof supervisingTeacherId == 'string') {
                    supervisingTeacherId = supervisingTeacherId.split(',')
                }


                this.getTeaList(this.courseScheduleDetailTeachersList, supervisingTeacherId, alreadySelectedTeachers);
                console.log(alreadySelectedTeachers,'alreadySelectedTeachers')
                alreadySelectedTeachers.forEach((item) => {
                    this.checkClassPatrolList.forEach((subItem) => {
                        if (item.name == subItem) {
                            let obj = {
                                id: item.id,
                                teacherName: item.name
                            }
                            console.log(item,'item1')
                            selectT.push(obj)
                        }
                    })
                })

                selectT = this.deduplication(selectT)
                this.tourDetailsData['selectT'] = selectT;
                console.log(this.tourDetailsData,'this.tourDetailsData')
                this.$emit('handlerGoClassPatrolClick', this.tourDetailsData)
            } else {
                this.$message.error('请选择督导教师')
            }
         },
         /**
          * @Description: 巡课/继续巡课
          * @DoWhat: 巡课/继续巡课
          * @UseScenarios: 巡课/继续巡课
          * @Attention: 无
          * @Author: 武东超
          */
          requestPool (item) {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            console.log(item,'itemitemitem')
            switch (item.isTourClass) {
                case '1':
                // 已巡课
                teachingResearchCourseScheduleTempModel.getSchoolClassRecordInfo({id: item.tourClassId, watchTitle: ''}).then((res) => {
                    if (res.data.code == '200') {
                        console.log(res.data.data,'详情', res.data.data.supervisingTeacherId)
                        let selectT = [];
                        this.getTeach(this.courseScheduleDetailSupervisingTeachersList, res.data.data.supervisingTeacherId ,selectT)
                        selectT = this.deduplication(selectT)
                        console.log(selectT,'selectT')
                        let obj = {
                            id: res.data.data.id,
                            type: 'detail',
                            list: [res.data.data],
                            selectT: selectT
                        };

                        this.$emit('handlerGoClassPatrolClick', obj)
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
                    break;
                case '2':
                    // 继续巡课
                    teachingResearchCourseScheduleTempModel.getContinueTourInfo({id: item.tourClassId, watchTitle: ''}).then((res) => {
                        if (res.data.code == '200') {
                            let selectT = [];

                            this.getTeach1(this.courseScheduleDetailSupervisingTeachersList, res.data.data ,selectT)

                            selectT = this.deduplication(selectT)
                            let obj = {
                                id: res.data.data[0].id,
                                type: 'continueTour',
                                list: res.data.data,
                                selectT: selectT,
                                form: 'closeAdd'
                            };
                            console.log(obj,'obj')
                            this.$emit('handlerGoClassPatrolClick', obj)

                        } else if (res.data.msg){
                            this.$message.error(res.data.msg)
                        }
                    })
                    break;
                default:
                    break;
            }
        },
        deduplication (arr) {
            let map = new Map();
            for (let item of arr) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                }
            }
            return [...map.values()];
        },
        deduplication1 (arr) {
            let map = new Map();
            for (let item of arr) {
                if (!map.has(item.value)) {
                    map.set(item.value, item);
                }
            }
            return [...map.values()];
        },
         getTeaList (list, supervisingTeacherId, alreadySelectedTeachers) {
            console.log(list, supervisingTeacherId,alreadySelectedTeachers,'list, supervisingTeacherId')
            list.forEach((item) => {
                supervisingTeacherId.forEach((subItem) => {
                    if (item.id == subItem) {
                        alreadySelectedTeachers.push(item)
                    }
                })

                if (item.children.length > 0) {
                    this.getTeaList (item.children, supervisingTeacherId, alreadySelectedTeachers)
                }
            })
         },
        /**
         * @Description: 关闭
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-10 10:31:04
         */
        handleCloseClassDetailsEditor () {
            this.dialogDetailsEditor.dialogVisible = false;
            setTimeout(() => {
                this.clearPopUpEditingDetailsData()
            }, 200)
        },
        handleCloseClassDetailsEmpty(){
            // 清空级联复选与日期
            this.$refs.J_cascader.clearnode()
            this.$refs.J_datePicker.cleardate()
            this.dialogDetailsEmpty.dialogVisible = false;
        },
        handleCloseClassDetailsDuplicate () {
            this.dialogDetailsDuplicate.dialogVisible = false;
            this.handleCloseClassDetailsEditor();
            this.changeQuery();
        },
        /**
         * @Description: 关闭PDF弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 14:20:21
         */
         handleCloseExportPdf () {
            this.dialogExportPdf.dialogVisible = false;
         },
        /**
         * @Description: 重复提交
         * @DoWhat: 重复提交
         * @UseScenarios: 重复提交
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 10:04:07
         */
         handlerRepeatResults () {
            if(this.courseScheduleDetail.repeatType == '3') {
                this.handleCloseClassDetailsDuplicate();
                this.courseScheduleDetail.repeatType = '1'
                return;
            }
            if (this.comPointer === 'detail') {
                    this.handleCloseClassDetailsEditor()
                    return;
                }
                const reqObj = JSON.parse(JSON.stringify(this.courseScheduleDetail));
                reqObj.supervisingTeacherId = reqObj.supervisingTeacherId.toString();
                console.log(reqObj,'reqObj')
                if (reqObj.repeatClass != '0') {
                    reqObj.list[0].scheduleDate = '';
                } else {
                    reqObj.list[0].week = '';
                }
                reqObj.classdata=[]
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getSchoolCourseSave(reqObj).then((res) => {
                    if (res.data.code == '200') {
                        console.log(res.data.data, '保存接口')
                        if (Object.keys(res.data.data).length == 0) {
                            this.$message.success('创建/编辑成功');
                            console.log(this.queryList.courseType, this.courseScheduleDetail,'发送数据')
                            this.dialogDetailsDuplicate.dialogVisible = false;
                            this.handleCloseClassDetailsEditor();
                            this.getNewTableList();
                        }
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
         },
         /**
          * @Description: 获取列表最新数据
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author: 武东超
          * @Date: 2023-02-02 09:02:50
          */
         getNewTableList () {
            console.log('123')
            switch (this.queryList.courseType) {
                case '1':
                case '2':
                    this.changeQuery();
                    break;
                case '3':
                    this.gradeTableList ()
                    break;
                default:
                    break;
            }
         },
        // 详情操作按钮相关
         /**
          * @Description: 课程表编辑
          * @DoWhat: 课程表编辑
          * @UseScenarios: 课程表编辑
          * @Attention: 无
          * @Author: 武东超
          * @Date: 2022-11-10 12:05:20
          */
        detailEdit (data) {
            console.log('点击编辑了----',data)
            this.dialogDetailsEditor.title = '编辑';
            this.comPointer = 'edit'
            this.repeatClassDisabled = true;
            this.dayDetail = false;
        },
        /**
         * @Description: 删除单次课程表
         * @DoWhat: 删除单次课程表
         * @UseScenarios: 删除单次课程表
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 15:20:35
         * @param {*} data
         */
        detailDelSingleCourse (data, type) {
            console.log(data, type, '周 列表删除单次课程表')
            this.deleteComposite ({id: data.id, deleteType: type, courseType: this.queryList.courseType })
        },
        /**
         * @Description: 删除全部课程表
         * @DoWhat: 删除全部课程表
         * @UseScenarios: 删除全部课程表
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 15:20:58
         * @param {*} data
         */
        detailDelAllCourses (data, type) {
            console.log(data, '周 列表删除全部课程表')
            this.deleteComposite ({id: data.id, deleteType: type, courseType: this.queryList.courseType })
        },
        /**
         * @Description: 课程表确定
         * @DoWhat: 课程表确定
         * @UseScenarios: 课程表确定
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 15:22:46
         * @param {*} data
         */
        async detailDetermine (courseScheduleDetail) {

            if (this.queryList.courseType == '3') {
              let date1 = new Date(courseScheduleDetail.list[0].scheduleDate)
              let year = date1.getFullYear()
              let monthh = date1.getMonth()+1
              let dates = date1.getDate()
              let weekIndex = date1.getDay()
              let weekList = ['7','1','2','3','4','5','6']
               courseScheduleDetail.list[0].week=weekList[weekIndex]

            }else{
                // courseScheduleDetail.scheduleClassId = this.queryList.scheduleClassId//点击新增课程时将班级id传过去
            }
            this.getSubjectName(this.courseScheduleDetailSubjectsList, courseScheduleDetail);
            let status = await this.duplicateCheck(courseScheduleDetail);
            if (status == 'error') {
                return false;
            }  else if (status){
                if (this.comPointer === 'detail') {
                    this.handleCloseClassDetailsEditor()
                    return;
                }
                const reqObj = JSON.parse(JSON.stringify(courseScheduleDetail));
                reqObj.supervisingTeacherId = reqObj.supervisingTeacherId.toString();
                console.log(reqObj,'reqObj')
                if (reqObj.repeatClass != '0') {
                    reqObj.list[0].scheduleDate = '';
                } else {
                    reqObj.list[0].week = '';
                }

                if (this.comPointer == 'edit') {
                    // reqObj['repeatClass'] = '0';
                    reqObj.list[0].scheduleDate = reqObj.scheduleTime;

                }
                // return
                reqObj.classdata=[]
                console.log("-------这个是",reqObj)
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getSchoolCourseSave(reqObj).then((res) => {
                    if (res.data.code == '200') {
                        console.log(res.data.data, '保存接口')
                        if (Object.keys(res.data.data).length == 0) {
                            this.$message.success('创建/编辑成功');
                            console.log(this.queryList.courseType, this.courseScheduleDetail,'发送数据')
                            this.handleCloseClassDetailsEditor();
                            this.getNewTableList();
                        }
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
            } else {
                this.dialogDetailsEditor.dialogVisible = false;
                this.dialogDetailsDuplicate.dialogVisible = true;
                console.log('重复的')
            }

        },
        /**
         * @Description: 获取科目
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-02-02 09:27:57
         */
         getSubjectName (list, courseScheduleDetail) {
            list.forEach((item) => {
                if (courseScheduleDetail.subjectId == item.id) {
                    courseScheduleDetail.subjectName = item.name;
                }
            })
         },
        /**
         * @Description: 课程添加重复校验
         * @DoWhat: 课程添加重复校验
         * @UseScenarios: 课程添加重复校验
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 09:34:41
         */
        async duplicateCheck (courseScheduleDetail) {

            let status;
            let localCourseScheduleDetail = courseScheduleDetail
            // localCourseScheduleDetail.classdata={}
            const reqObj = JSON.parse(JSON.stringify(localCourseScheduleDetail));
            reqObj.supervisingTeacherId = reqObj.supervisingTeacherId.toString();
                console.log(reqObj,'reqObj')
                if (reqObj.repeatClass != '0') {
                    reqObj.list[0].scheduleDate = '';
                } else {
                    reqObj.list[0].week = '';
                }
            if (this.comPointer == 'edit') {
                console.log(reqObj,'reqObj edit 校验')
                // reqObj['startScheduleTime'] = '';
                // reqObj['endScheduleTime'] = '';
                reqObj['repeatClass'] = '0';
                reqObj.list[0].scheduleDate = reqObj.scheduleTime;

            }
            reqObj.classdata = []
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            await teachingResearchCourseScheduleTempModel.getCourseDuplication(reqObj).then((res) => {

                if (res.data.code == '200') {
                    console.log(res.data.data.flag,'校验数据')
                    if (typeof res.data.data.flag == 'boolean' && !res.data.data.flag) {
                        this.tips = res.data.data.list;
                    }
                    status = res.data.data.flag;

                } else if (res.data.code == '100301') {
                    status = 'error';
                    this.$message.error(res.data.msg);
                } else if (res.data.msg) {
                    status = false;
                    this.$message.error(res.data.msg);
                }
            })
            return status;
        },
        /**
         * @Description: 课程表取消
         * @DoWhat: 课程表取消
         * @UseScenarios: 课程表取消
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 15:23:03
         * @param {*} data
         */
        detailCancellation (data) {
            this.handleCloseClassDetailsEditor()
        },
        /**
         * @Description: 获取班级名称
         * @DoWhat: 获取班级名称
         * @UseScenarios: 获取班级名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 16:38:25
         */
         getFindClassList (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.queryList.scheduleClassId = list[i].id;
                    console.log(list[i].name,'list[i]---00')
                    break;
                }
            }
         },
         getFindClassList1 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.queryList.scheduleClassId = list[i].id;
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
         },
         getFindClassList2 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList (list[i].child);
                    break;
                } else if (list[i]){
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
         },
        /**
         * @Description: 获取教师名称
         * @DoWhat: 获取教师名称
         * @UseScenarios: 获取教师名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 17:21:26
         */
        getFindTeacher (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].children.length > 0) {
                    this.getFindTeacher (list[i].children);
                    break;
                } else if (list[i]){
                    this.queryList.classTeacherId = list[i].id;
                    this.courseScheduleDetail.classTeacherId = list[i].id;
                    break;
                }
            }
        },
        getFindTeacher1 (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].children.length > 0) {
                    this.getFindTeacher (list[i].children);
                    break;
                } else if (list[i]){
                    this.courseScheduleDetail.classTeacherId = list[i].id;
                    break;
                }
            }
        },
        /**
         * @Description: 获取年级第一个数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 10:30:37
         */
         getFindGrade (list) {
            for(var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindGrade (list[i].child);
                    break;
                } else if (list[i].organType == '3'){
                    this.queryList.scheduleGradeId = list[i].id;
                    break;
                }
            }
         },
        /**
         * @Description: 集中处理函数，某些值改变则改变赋值操作
         * @DoWhat: 集中处理函数，某些值改变则改变赋值操作
         * @UseScenarios: 集中处理函数，某些值改变则改变赋值操作
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:21:39
         */
         assignmentOperations () {
            this.scheduleClassIdPointer = this.queryList.scheduleClassId;
            console.log(this.scheduleClassIdPointer,'年级指针')
         },
        /**
         * @Description: 选择班级变化，请求新的作息时间表
         * @DoWhat: 选择班级变化
         * @UseScenarios: 选择班级变化
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:31:31
         */
         handlerScheduleClassIdChange () {
            console.log('年级变化', this.queryList.scheduleClassId)
            this.assignmentOperations()
            this.reClassTime('loading');
         },
        /**
         * @Description: 更改班级
         * @DoWhat: 更改班级
         * @UseScenarios: 更改班级
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-21 17:04:42
         */
         handlerClassTeacherChange () {
            this.courseScheduleDetail.classTeacherId = this.queryList.classTeacherId;
            this.reClassTime ()
         },
        /**
         * @Description: 更改老师
         * @DoWhat: 更改老师
         * @UseScenarios: 更改老师
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-25 15:40:17
         */
         handlerTeacherChange () {
            this.reClassTime ()
         },
        /**
         * @Description: 更改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-31 19:06:25
         */
         handlerGradeChange () {
            this.courseList = []
            console.log(this.queryList.scheduleGradeId,'年级')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSpliceOrganList({'schoolId': this.schoolId, 'id': this.queryList.scheduleGradeId,'jobScope':[1,2,3]}).then((res) => {
                if (res.data.code == '200') {
                    res.data.data.forEach((item) => {
                        console.log(item,'item')
                        let obj = {
                            id: item.id,
                            label: `${item.name}`,
                        };
                        this.courseList.push(obj)
                    })
                    console.log(res.data.data,'根据年级获取班级')
                    this.getClassTime();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
         },
         getClassTime () {
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            this.conditionList.gradeId = this.queryList.scheduleGradeId;
            console.log(this.queryList, this.conditionList,'年级课程表获取表头')
            teachingResearchCourseScheduleTempModel.getClassWeek(this.conditionList).then((res) => {
                this.weekList = [];
                if (res.data.code == '200') {
                    console.log(res.data.data,'年级上课星期数据')
                    res.data.data.forEach((item) => {
                        let obj = {
                            _id: item.id,
                            label: `${item.name}  ${item.startTime}-${item.endTime}`,
                            time: `${item.startTime}-${item.endTime}`,
                            starTime: item.startTime,
                            gradeId: item.gradeId,
                            endTime: `${item.endTime}`
                        };
                        this.weekList.push(obj)
                    })
                    console.log(this.weekList,'this.weekList')
                    this.gradeTableList()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
            this.classnameandteacher()
         },
        /**
         * @Description: 年级table列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 11:44:30
         */
         gradeTableList () {
            this.getDataLoading = true;
            this.queryList.dayWeek = 'week'; // 表头展示多个

            this.filterQueryList.selectDay = this.queryList.selectDay;
            this.filterQueryList.courseType = '3';
            this.filterQueryList.startTime = this.queryList.selectDay;
            this.filterQueryList.endTime = this.queryList.selectDay;
            this.filterQueryList.scheduleGradeId = this.queryList.scheduleGradeId;
            console.log(this.queryList, this.filterQueryList,'获取年级table')
            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.getSchoolCourseList(this.filterQueryList).then((res) => {
                if (res.data.code == '200') {
                    console.log(this.weekList, '上边', this.courseList,'左侧')
                     console.log(res.data.data,'res.data.data')


                      let str = Object.prototype.toString,
                          type = '[object Object]',
                          len = this.weekList.length,
                          data = res.data.data;

                     for (let i = 0; i < len; i++) {
                        console.log('进来来说')
                        this.$set(this.weekList[i], 'id', i);
                        this.$set(this.weekList[i], 'data', this.queryList.selectDay)
                        this.$set(this.weekList[i], 'child', []);
                     }

                     this.weekList.forEach((item, index) => {
                        this.courseList.forEach((subItem, subIndex) => {
                            let obj = {
                                _uuid: subItem.id,
                                _uuName: subItem.label,
                                _time: this.filterQueryList.selectDay,
                                _day: item.data,
                                _class: subItem.id,
                                _deadline: item.data +' '+ item.starTime,
                                _timestamp: new Date(item.data +' '+ item.starTime).getTime(),
                                _gradeId: item.gradeId,
                                checkState:false,
                                operateShow:false
                            };
                            console.log(obj,'obj班级')
                            item.child.push(obj);
                        });
                    })
                    if(str.call(data) !== type) {
                        this.weekList.forEach((item, index) => {
                            item.child.forEach((pItem, pIndex) => {
                                data.forEach((subItem) => {
                                    if (item._id === subItem.restTimeId && pItem._uuid == subItem.scheduleClassId) {
                                        item.child.splice(pIndex, 1, Object.assign(subItem ,pItem))
                                    }
                                })
                            })
                        })
                    }
                     console.log(this.weekList,'this.weekList')
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }

                this.getDataLoading = false;
            })
         },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-16 22:19:54
         */
         deleteComposite (data) {
            console.log(data,'data删除添加')
            // return;
            let reqObj = {
                id: data.id,
                deleteType: data.deleteType,
                courseType: data.courseType,
            }

            if(data.courseType == '3') {
                reqObj['scheduleGradeId'] = this.queryList.scheduleGradeId
            }

            const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel.deleteSchoolCourse(reqObj).then((res) => {
                if (res.data.code == '200') {

                    this.$message.success('删除成功')
                    if (this.dialogDetailsEditor.dialogVisible) {
                        this.handleCloseClassDetailsEditor();
                    };
                    this.getNewTableList();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })

         }
    },
    watch: {
        'queryList.courseType': {
            handler () {
                /**
                 * 1. 课表类型变化时，新增课程表数据变化
                 * 2. 请求新的课程表接口
                 * */
                this.courseScheduleDetail.courseType = this.queryList.courseType;
                if (this.queryList.courseType == '3') {
                    console.log('3', this.queryList.selectDay)
                    this.gradeTableList ()
                } else {
                    this.changeQuery()
                }

            },
            deep: true
        },
        'queryList.classTeacherId': {
            handler () {
                this.changeQuery()
            },
            deep: true
        },
        'queryList.selectDay': {
            handler () {
                if (this.queryList.courseType == '3') {
                    console.log('3', this.queryList.selectDay)
                    this.gradeTableList ()
                } else {
                    this.changeQuery()
                }

            },
            deep: true
        },
        'queryList.isTourClass': {
            handler () {
                if (this.queryList.courseType == '3') {
                    console.log('3', this.queryList.selectDay)
                    this.gradeTableList ()
                } else {
                    this.changeQuery()
                }
            },
            deep: true
        },
    }
}
</script>
<style lang="scss" scoped>
    .teaching-research-course-schedule-temp {
        box-sizing: border-box;
        // padding: 10px;
        .content-main {
            background-color: #fff;
            box-sizing: border-box;
            padding: 16px 20px;

        }
    }
    .options-btn {
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        margin-top: 10px;
        // padding: 10px;
    }
    .btn-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }
    .tips {
        display: flex;
        justify-content: center;
        padding: 0px 0px 20px 0;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808487;
    }
    .request-pool {
        display: flex;
        flex-wrap: wrap;
        .pool {
            display: flex;
            margin: 0 10px;
            cursor: pointer;
        }
    }
    ::v-deep {
        .el-dialog{
            .el-form-item--small .el-form-item__content{
                line-height: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
</style>
